<template>
   <ObiText :as="as" :type="type" v-bind="attrs" v-on="$listeners" :class="classList" class="mb-2">
      <ObiButton v-if="edit" class="bg-primary no-border p-0 me-2" :icon="EditIcon" @click="$emit('edit')" />
      <ObiButton class="bg-white no-border p-0 me-2" :icon="IconRemove" @click="$emit('remove')" />
      <img class="obi-delete-button__icon" height="24" :src="icon" v-if="icon" />
      <span class="obi-delete-button--text ms-2" v-if="text" v-html="text" /> <slot v-else />
   </ObiText>
</template>

<script>
import { omit, keys } from 'lodash';
import IconRemove from '@/components/Icons/general/IconRemove.svg';
import EditIcon from '@/components/Icons/portals/EditIcon.svg';

export default {
   name: 'ObiDeleteButton',

   components: {},

   props: {
      as: {
         type: String,
         default: 'button',
      },
      type: {
         type: String,
         default: 'button',
      },
      active: {
         type: Boolean,
         default: false,
      },
      text: {
         type: String,
      },
      icon: {
         type: String,
      },
      iconRight: {
         type: Boolean,
         default: false,
      },
      iconHidden: {
         type: Boolean,
         default: false,
      },
      color: {
         type: String,
         default: 'primary',
      },
      size: {
         type: String,
      },
      outline: {
         type: Boolean,
         default: false,
      },
      wider: {
         type: Boolean,
         default: false,
      },
      block: {
         type: Boolean,
         default: false,
      },
      loading: {
         type: Boolean,
         default: false,
      },
      close: {
         type: Boolean,
         default: false,
      },
      edit: {
         type: Boolean,
         default: false,
      },
   },

   computed: {
      classList() {
         if (this.close) {
            return 'btn-close';
         }

         return [
            'obi-button btn',
            this.block && `w-100`,
            this.active && `active`,
            this.wider && `btn-wider`,
            this.size && `btn-${this.size}`,
            this.icon && `obi-button--icon`,
            this.iconHidden && `--icon-hidden`,
            this.loading && `obi-button--loading`,
            this.color && `btn-${this.outline ? 'outline-' : ''}${this.color}`,
         ];
      },

      attrs() {
         return omit(this.$attrs, keys(this.$props));
      },
   },
   data() {
      return {
         IconRemove,
         EditIcon,
      };
   },
};
</script>

<style lang="scss" scoped>
.obi-delete-button {
   &.obi-delete-button--icon {
      grid-gap: 0.5rem;
      align-items: center;
      display: inline-grid;
      grid-auto-flow: column;

      &.--icon-hidden {
         .obi-delete-button__icon {
            display: none;
         }
      }
   }

   &.obi-delete-button--loading {
      align-items: center;
   }

   .obi-delete-button__icon {
      line-height: 1;
      font-size: 28px;
      display: inline-flex;
   }
}
</style>
